import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"

const NotFoundPage = props => (
  <>
    <SEO
      title="Página inexistente"
      pathname="/404"
      description="Sicarpo - Página não encontrada."
    />
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        alignContent: "center",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${props.data.dataJson.projectos.img.publicURL})`,
        color: "#DF4E10",
      }}
    >
      <h1
        style={{
          backgroundColor: "#ffffff",
          padding: "15px",
          textAlign: "center",
        }}
      >
        Página inexistente...
      </h1>
      <h2
        style={{
          backgroundColor: "#ffffff",
          padding: "15px",
          textAlign: "center",
        }}
      >
        Mas não faz mal...
      </h2>
      <span
        style={{
          backgroundColor: "#ffffff",
          padding: "15px",
          textAlign: "center",
        }}
      >
        <img
          style={{ width: "50%", height: "50%" }}
          alt="Fantasminha da Sicarpo"
          src="/images/404.webp"
        ></img>
      </span>
      <p
        style={{
          backgroundColor: "#ffffff",
          padding: "15px",
          textAlign: "center",
        }}
      >
        <Link to="/">Clique aqui para voltar à página inicial!</Link>
      </p>
    </div>
  </>
)

export default NotFoundPage

export const query = graphql`
  query {
    dataJson {
      projectos {
        img {
          publicURL
        }
      }
    }
  }
`
